/* You can add global styles to this file, and also import other style files */
$staffmark-blue: #0098DB;
$staffmark-orange: #FF4C41;
$staffmark-gray: #F6F5F5;

@font-face {
  font-family: 'LibreFranklin';
  src:  url('/assets/LibreFranklin-Regular.woff') format('woff');
}

* {
  font-family: 'LibreFranklin' !important;
}
.form-check-input, .form-control{
  border-color: grey !important;
}

.was-validated .form-control:invalid{
  border-color: #dc3545 !important;
}

.was-validated .form-control:valid, .was-validated .form-select:valid{
  border-color: #198754 !important;
  background-image: none !important;
}

.card-body{
  padding: 0 !important;
}

.container{
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

#employmentRow_1,#employmentRow_2,#employmentRow_3,#employmentRow_4{
  border-top:1px solid var(--bs-border-color-translucent) !important;
  padding-top: 1rem !important;
}

.hideHistoryItem{
  display:none !important;
}

.showHistoryItem{
}
#brandLogoImage{
  width: 50%;
}

.btn-continue-with-app {
  background-color: $staffmark-blue !important;
  border-color: $staffmark-blue !important;
}

body {
  background-color: $staffmark-gray !important;
}

.btn-primary {
  background-color: $staffmark-blue !important;
  border: none !important;
  border-radius: 0 !important;
}

.form-check-input:checked {
  background-color: $staffmark-blue !important;
}